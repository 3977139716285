@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600;700&display=swap');

:root {
  /* color value */
  --white: #fff;
  --dark: #1e1d1d;
  --red: #e23838;
  --blue: #004aad;
  --hard-dark: #131212;
  --offwhite: #f5f5f5;
  --light-white: rgba(255, 255, 255, 0.5);
  --dark-padded: rgba(30, 29, 29, 0.5);

  /* font size value */
  --label-f15: 15px;

  /* line height value */
  --lh-1-2: 1.2;
}

:focus {
  outline: 0 !important;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

/* Typespace */
#poppins__bold,
.poppins__bold {
  font-family: 'Poppins';
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.3;
}
#poppins__semibold,
.poppins__semibold {
  font-family: 'Poppins';
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 1.3;
}
#arial__reg,
.arial__reg {
  font-family: Arial;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 1.3;
}
/* font Size */
h1 {
  font-size: 50px !important;
}
h2 {
  font-size: 40px !important;
}
h3 {
  font-size: 35px !important;
}
h4 {
  font-size: 30px !important;
}
h5 {
  font-size: 25px !important;
}
h6,
.sec__content__subheader {
  font-size: 18px !important;
}
p {
  font-size: 16px;
}
/* font Size */
/* Typespace */

/* Color Class */
/* --background */
.cc__white__bg {
  background-color: var(--white) !important;
}
.cc__dark__bg {
  background-color: var(--dark) !important;
}
.cc__blue__bg {
  background-color: var(--blue) !important;
}
.cc__red__bg {
  background-color: var(--red) !important;
}
.cc__darkpadded__bg {
  background-color: var(--dark-padded) !important;
}
/* --color */
.cc__white__c {
  color: var(--white);
}
.cc__dark__c {
  color: var(--dark);
}
.cc__blue__c {
  color: var(--blue);
}
.cc__red__c {
  color: var(--red);
}
.cc__darkpadded__c {
  color: var(--dark-padded);
}
/* Color Class */

/* Global Style */
html,
body {
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: var(--offwhite) !important;
}
/* -- Text Ellipsis 3 rows */
.ellipsis_nft_desc {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  min-height: 50px !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ellipsis_events_desc {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  min-height: 10px !important;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ellipsis_merchandise_name {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  min-height: 52px !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media only screen and (max-width: 1143px){
  .ellipsis_events_desc {
    min-height: 105px !important;
  }
}
/* -- Text Ellipsis 3 rows */

/* -- Pulse Button animation green */
.pulse_green_btn_anim {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.pulse_green_btn_anim:before {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 60px;
  height: 60px;
  background: rgba(2, 198, 214, 0.7);
  border-radius: 50%;
  -webkit-animation: pulse-border 1500ms ease-out infinite;
          animation: pulse-border 1500ms ease-out infinite;
  z-index: -1;
}
.pulse_green_btn_anim:after {
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  width: 60px;
  height: 60px;
  background: #02c6d6;
  border-radius: 50%;
  transition: all 200ms;
  z-index: 1;
}
.pulse_green_btn_anim span {
  position: relative;
  margin-left: 4px;
  z-index: 2;
}
.pulse_green_btn_anim:hover:after {
  background-color: darken(#02c6d6, 10%);
}
.pulse_green_btn_anim:hover {
  cursor: pointer;
  background-color: #02C6D6;
}
.pulse_green_recbtn_anim:hover {
  cursor: pointer;
}
.pulse_green_recbtn_anim:before {
  background: #02c6d6;
  border-radius: 50%;
  transition: all 200ms;
  z-index: 2;
}
.pulse_green_recbtn_anim::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(2, 198, 214, 0.7);
  border-radius: 2rem;
  -webkit-animation: pulse-border-rec 1500ms ease-out infinite;
          animation: pulse-border-rec 1500ms ease-out infinite;
  z-index: 1;
}
/* rgba(2, 198, 214, 0.7) */
@-webkit-keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(2);
    opacity: 0;
  }
}
@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(2);
    opacity: 0;
  }
}
@-webkit-keyframes pulse-border-rec {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scaleY(1.5) scaleX(1.2);
    opacity: 0;
  }
}
@keyframes pulse-border-rec {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scaleY(1.5) scaleX(1.2);
    opacity: 0;
  }
}
/* -- Pulse Button animation green */

/* Polygon Countdown BG */
.polygon_countdown_bg {
  clip-path: polygon(0 30%, 100% 0, 100% 100%, 0% 100%);
  -webkit-clip-path: polygon(0 30%, 100% 0, 100% 100%, 0% 100%);
}
/* Polygon Countdown BG */

/* NFTCardlist.tsx - Celebrity NFTs [Image] */
.nftcardlist_img {
  border-radius: 1rem;
}
/* NFTCardlist.tsx - Celebrity NFTs [Image] */

/* About Us Page */
/* Header Gradient */
.aboutus_header {
background: #02C6D6;
background: linear-gradient(to right, #02C6D6 0%, #BE06BA 72%, #FF4F42 98%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}
/* Header Gradient */

/* Arrow responsive position */
.orange_arrow {
  position: absolute;
  transform: rotate(90deg);
  right: -95px;
  width: 170px;
}
.pink_arrow {
  position: absolute;
  left: -95px;
  width: 170px;
  transform: rotate(-90deg);
}
.green_arrow {
  position: absolute;
  width: 170px;
  bottom: 0;
  left: "-10%";
}
@media only screen and (max-width: 767px) {
  .orange_arrow {
    transform: rotate(0deg);
    right: none;
    left: 0;
    bottom: -0px;
    width: 130px;
  }
  .pink_arrow {
    left: 0;
    bottom: 0;
    width: 130px;
    transform: rotate(0deg);
  }
  .green_arrow {
    position: absolute;
    width: 130px;
    bottom: 0;
    left: 0;
  }
}
/* Arrow responsive position */
/* About Us Page */

/* Roadmap */
.roadmap_node_line::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 2px;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1;
}
.roadmap_q1,
.roadmap_q3,
.roadmap_2023q1 {
  width: 100%;
  top: -268px;
  left: -0px;
}

.roadmap_q2,
.roadmap_q4,
.roadmap_lastchild {
  width:100%;
  top: -153px;
  left: 0;
}
.roadmap_q1_bg {
  width: 292px;
  background: linear-gradient(90deg, #05C4D6 18.43%, #3363C0 75.33%);
}
.roadmap_q2_bg {
  width:254px;
  background: linear-gradient(269.25deg, #883EC3 16.69%, #3464C1 77%);
}
.roadmap_q3_bg {
  width: 257px;
  background: linear-gradient(89.8deg, #883EC3 31.38%, #E33A99 99.78%);
}
.roadmap_q4_bg{
  width: 192px;
  background: linear-gradient(269.8deg, #FF4F44 0.17%, #DF3B9C 60.91%);
}
.roadmap_2023q1_bg {
  width: 356px;
  background: linear-gradient(269.8deg, #FF4F44 0.17%, #DF3B9C 60.91%);
}
.roadmap_lastchild_bg {
  width: 192px;
  background: linear-gradient(0deg, #FF4F44, #FF4F44);
}
.roadmap_year {
  bottom: -130px;
  left: -4px;
}
.roadmap_m_startyear::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 1px;
  left: 0;
  background-color:#C2C2C2;
}
.mobile_roadmap_vline::before {
  position: absolute;
  content: '';
  top: 50%;
  left: 29px;
  width: 2px;
  height: 70%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.8);
}
.mobile_roadmap_q1_bg {
  background: linear-gradient(90deg, #05C4D6 18.43%, #3363C0 75.33%);
}
.mobile_roadmap_q2_bg {
  background: linear-gradient(269.25deg, #883EC3 16.69%, #3464C1 77%);
}
.mobile_roadmap_q3_bg {
  background: linear-gradient(89.8deg, #883EC3 31.38%, #E33A99 99.78%);
}
.mobile_roadmap_q4_bg {
  background: linear-gradient(269.8deg, #FF4F44 0.17%, #DF3B9C 60.91%);
}
.mobile_roadmap_lastchicl_bg {
  background: linear-gradient(0deg, #FF4F44, #FF4F44);
}
@media only screen and (max-width: 1501px){
  .roadmap_q1_bg {
    position: relative;
    left: 115px;
  }
  .roadmap_2023q1_bg {
    position: relative;
    left: 100px;
  }
  .roadmap_q3_bg {
    position: relative;
    left: 90px;
  }
  .roadmap_lastchild_bg {
    position: relative;
    right: 55px;
  }
}
@media only screen and (max-width: 1441px){
   .roadmap_q1_bg {
    width: 265px;
  }
   .roadmap_q2_bg {
    width:230px;
  }
  .roadmap_q3_bg {
    width:235px;
  }
  .roadmap_q4_bg {
    width:175px;
  }
  .roadmap_2023q1_bg {
    width: 320px;
  }
  .roadmap_lastchild_bg {
    width: 175px;
  }
}
@media only screen and (max-width: 1220px){
  .roadmap_2023q1_bg {
    position: relative;
    left: 80px;
  }
}
@media only screen and (max-width: 1150px){
  .roadmap_2023q1_bg {
    position: relative;
    left: 60px;
  }
}
@media only screen and (max-width: 870px){
  .roadmap_q3_bg {
    position: relative;
    left: 45px;
  }
}
/* Roadmap */


/* Microsite Product for sale */
.microsite_productsale{
  position: relative;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center right;
}
.microsite_productsale::before{
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, #171923 0%, #293361 100%);
  opacity: .5 ;
  /* filter: blur(8px);
  -webkit-filter: blur(8px); */
}
/* Microsite Product for sale */

/* Featured Channel */
.filter_grayscale { /* Google Chrome, Safari 6+ & Opera 15+ */
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  transition: .5s;
}
.filter_grayscale:hover { /* Google Chrome, Safari 6+ & Opera 15+ */
  -moz-filter: grayscale(0);
  -ms-filter: grayscale(0);
  -o-filter: grayscale(0);
  filter: grayscale(0);
}
/* Featured Channel */

.wrapper,
#wrapper {
  width: 1240px;
  height: auto;
  margin: 0 auto;
}
.btn__w__icon a:hover .btn__icon__container {
  border-left: 2px solid var(--white);
}
/* Button Style */
/* --Button with Icon Style */
.btn__w__icon {
  position: relative;
}
.btn__w__icon a {
  position: relative;
  padding: 7px 50px 6px 10px;
  display: flex;
  color: var(--red);
  border: 2px solid var(--red);
  border-radius: 10px;
  background-color: var(--white);
  background: linear-gradient(to right, var(--red) 50%, var(--white) 50%);
  background-size: 200% calc(100% - 10px);
  background-position: right bottom;
  transition: all 0.5s ease-out;
}
.btn__w__icon a .btn__icon__container {
  position: absolute;
  width: 40px;
  height: 37px;
  top: -2px;
  right: -2px;
  border-radius: 0 10px 10px 0;
  background-color: var(--red);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 2px solid transparent;
  transition: all 0.5s ease-out;
}
.btn__w__icon a .btn__icon__container .btn__icon {
  color: var(--white);
}
.btn__w__icon a:hover {
  text-decoration: none;
  background-position: left bottom;
  color: var(--white);
}
.btn__w__icon a:hover .btn__icon__container {
  border-left: 2px solid var(--white);
}
/* --Button with Icon Style */

/* Fixed Size Button */
.btn__fsize {
  position: relative;
  display: flex;
  width: 170px;
  align-items: center;
  justify-content: space-between;
  padding: 7px 15px 6px;
  border-radius: 10px;
}
.btn__fsize .btn__fsize__icon {
  position: relative;
  font-size: 25px;
}
.btn__fsize:hover {
  text-decoration: none !important;
}
/* Fixed Size Button */
/* Button Style */
.sec__content__subheader {
  color: var(--dark-padded);
}
.sec__content__header {
  color: var(--red);
}
.sparator {
  width: 150px;
  height: 3px;
  background-color: var(--red);
  margin: 20px 0;
}
.sparator__c {
  width: 150px;
  height: 3px;
  background-color: var(--red);
  margin: 20px auto;
}
/* Section Header */

/* Arrow Down Animation */
.arrowdown-animation {
  position: relative;
  -webkit-animation: arrowdown 1.5s infinite;
          animation: arrowdown 1.5s infinite;
}

@-webkit-keyframes arrowdown {
  0% {
    margin-top: 0;
  }
  50% {
    margin-top: 22px;
  }
  100% {
    margin-top: 0;
  }
}

@keyframes arrowdown {
  0% {
    margin-top: 0;
  }
  50% {
    margin-top: 22px;
  }
  100% {
    margin-top: 0;
  }
}
/* Arrow Down Animation */

/* Responsive */
@media only screen and (max-width: 1441px) {
  /* Global Style */
  .wrapper,
  #wrapper {
    width: 100%;
    padding: 0 20px;
  }

  /* font Size */
  h1 {
    font-size: 45px !important;
  }
  h2 {
    font-size: 35px !important;
  }
  h3 {
    font-size: 30px !important;
  }
  h4 {
    font-size: 25px !important;
  }
  h5 {
    font-size: 20px !important;
  }
  h6,
  .sec__content__subheader {
    font-size: 16px !important;
  }
  p {
    font-size: 14px;
  }

  /* Typespace */
  #poppins__bold,
  .poppins__bold {
    line-height: 1.1;
  }
  #poppins__semibold,
  .poppins__semibold {
    line-height: 1.1;
  }
  #arial__reg,
  .arial__reg {
    line-height: 1.2;
  }

  .btn__w__icon a .btn__icon__container {
    height: 33px;
  }
}

@media only screen and (max-width: 1025px) {
  /* font Size */
  h1 {
    font-size: 40px !important;
  }
  h2 {
    font-size: 30px !important;
  }
  h3 {
    font-size: 25px !important;
  }
  h4 {
    font-size: 20px !important;
  }
  h5 {
    font-size: 18px !important;
  }
  h6,
  .sec__content__subheader {
    font-size: 15px !important;
  }
  p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 769px) {
  /* font Size */
  h1 {
    font-size: 35px !important;
  }
  h2 {
    font-size: 25px !important;
  }
  h3 {
    font-size: 20px !important;
  }
  h4 {
    font-size: 18px !important;
  }
  h5 {
    font-size: 15px !important;
  }
  h6,
  .sec__content__subheader {
    font-size: 14px !important;
  }
  p {
    font-size: 13px;
  }
  .btn__fsize {
    width: 140px;
  }
  .wrapper,
  #wrapper {
    width: 100%;
    padding: 0 10px;
  }
}
@media only screen and (max-width: 427px) {
  /* font Size */
  h1 {
    font-size: 30px !important;
  }
  h2 {
    font-size: 25px !important;
  }
  h3 {
    font-size: 15px !important;
  }
  h4 {
    font-size: 16px !important;
  }
  h5 {
    font-size: 14px !important;
  }
  h6,
  .sec__content__subheader {
    font-size: 14px !important;
  }
  p {
    font-size: 14px;
  }
  .btn__fsize {
    width: 140px;
  }
}
@media only screen and (max-width: 351px) {
  .btn__fsize {
    width: 100%;
    margin: 0 0 10px 0 !important;
  }
  h1 {
    font-size: 28px !important;
  }
  h2 {
    font-size: 23px !important;
  }
}
/* Fixed Size Button */
/* Button Style */

/* Responsive */
@media only screen and (max-width: 1441px) {
  /* Global Style */
  #wrapper {
    width: 100%;
  }

  /* font Size */
  h1 {
    font-size: 45px !important;
  }
  h2 {
    font-size: 35px !important;
  }
  h3 {
    font-size: 30px !important;
  }
  h4 {
    font-size: 25px !important;
  }
  h5 {
    font-size: 20px !important;
  }
  h6 {
    font-size: 16px !important;
  }
  p {
    font-size: 14px;
  }

  /* Typespace */
  #poppins__bold,
  .poppins__bold {
    line-height: 1.1;
  }
  #poppins__semibold,
  .poppins__semibold {
    line-height: 1.1;
  }
  #arial__reg,
  .arial__reg {
    line-height: 1.2;
  }

  .btn__w__icon a .btn__icon__container {
    height: 34px;
  }
}

@media only screen and (max-width: 1025px) {
  /* font Size */
  h1 {
    font-size: 40px !important;
  }
  h2 {
    font-size: 30px !important;
  }
  h3 {
    font-size: 25px !important;
  }
  h4 {
    font-size: 20px !important;
  }
  h5 {
    font-size: 18px !important;
  }
  h6 {
    font-size: 15px !important;
  }
  p {
    font-size: 14px;
  }
}

.banner__container {
  position: relative;
  width: 100%;
  background: url(/images/default-bg.jpg) no-repeat center;
  background-size: cover;
  padding: 72px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.banner__content {
  position: relative;
  width: 700px;
  margin: 100px 10px 250px;
}
.banner__content .banner__tagline {
  position: relative;
  text-align: center;
  margin: 0 0 20px;
}
.banner__content .banner__subtagline {
  position: relative;
  text-align: center;
  margin: 0 0 50px;
}
.banner__cta__container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.updown__icon__anim__container {
  position: absolute;
  bottom: 130px;
}
.updown__icon__anim {
  position: relative;
  width: 30px;
  height: 50px;
  border: 1px solid var(--dark);
  border-radius: 15px;
}
.updown__icon {
  position: absolute;
  -webkit-animation: UpDown 2s linear infinite;
          animation: UpDown 2s linear infinite;
  left: 50%;
  top: 10px;
  transform: translateX(-50%);
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: var(--dark);
}
@-webkit-keyframes UpDown {
  0% {
    top: 10px;
  }
  50% {
    top: 70%;
  }
  100% {
    bottom: 0;
  }
}
@keyframes UpDown {
  0% {
    top: 10px;
  }
  50% {
    top: 70%;
  }
  100% {
    bottom: 0;
  }
}

/* NFTCardList */
/* .nftcardlist__post {
  position: relative;
  top: -120px;
} */
/* NFTCardList */

/* responsive */
@media only screen and (max-width: 769px) {
  .banner__content {
    position: relative;
    width: 100%;
    padding: 0 50px;
    margin: 60px 10px 220px;
  }
  .banner__content .banner__subtagline {
    margin: 0 0 30px;
  }
}
@media only screen and (max-width: 500px) {
  .banner__content {
    padding: 0 10px;
  }
  .banner__content {
    margin: 20px 10px 220px;
  }
}
@media only screen and (max-width: 351px) {
  .banner__cta__container {
    position: relative;
    text-align: center;
    display: flex;
    flex-wrap: wrap;

    margin: 0 auto;
  }
}

/* ArtistPage css */
.user__nft__collection__container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.user__nft__collection__frame {
  position: relative;
  width: calc(20% - 40px);
  height: 350px;
  border: 1px dashed var(--dark-padded);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: var(--dark-padded);
  margin: 20px;
}

/* responsive */
@media only screen and (max-width: 1201px) {
  .user__nft__collection__frame {
    width: calc(25% - 40px);
    height: 250px;
  }
}
@media only screen and (max-width: 901px) {
  .user__nft__collection__frame {
    width: calc(25% - 20px);
    margin: 10px;
  }
}
@media only screen and (max-width: 769px) {
  .user__nft__collection__frame {
    width: calc(33.333% - 20px);
    margin: 10px;
  }
}
@media only screen and (max-width: 601px) {
  .user__nft__collection__frame {
    width: calc(50% - 20px);
    margin: 10px;
  }
}
@media only screen and (max-width: 351px) {
  .user__nft__collection__frame {
    width: calc(100% - 20px);
    margin: 10px;
  }
}

/* Rare NFT section */
.cnft__list__sec__container {
  position: relative;
  padding: 50px 0;
}
.cnft__list__sec {
  margin: 0 0 0 20px;
}
.cnft__list__vip {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.cnft__list__vip__item {
  width: calc(20% - 40px);
  margin: 20px;
}

/* responsive */
@media only screen and (max-width: 1201px) {
  .cnft__list__vip__item {
    width: calc(25% - 40px);
  }
}
@media only screen and (max-width: 901px) {
  .cnft__list__vip__item {
    width: calc(25% - 0px);
    margin: 0;
  }
  .cnft__list__sec {
    margin: 0 0 0 10px;
  }
}
@media only screen and (max-width: 769px) {
  .cnft__list__vip__item {
    width: 33.333%;
  }
  .cnft__list__sec__container {
    padding: 30px 0;
  }
  .cnft__collection__sec {
    padding: 30px 0;
  }
}
@media only screen and (max-width: 601px) {
  .cnft__list__vip__item {
    width: 50%;
  }
  .br_none {
    display: none;
  }
}
@media only screen and (max-width: 351px) {
  .cnft__list__vip__item {
    width: 100%;
  }
}
/* Rare NFT section */

/* User Collection NFT */
.cnft__collection__sec {
  position: relative;
  width: 100%;
  padding: 50px 0;
}
.cnft__collection__header {
  position: relative;
}
/* User Collection NFT */

/* Card Campaign */
.campaigns__cards {
  background-color: var(--white);
}
.campaigns__cards__icon {
  background-color: var(--red);
  color: var(--white);
}
.campaigns__cards__counts {
  line-height: 0.8 !important;
}
.campaigns__cards__title {
  position: relative;
  display: block;
  display: -webkit-box;
  max-width: 100%;
  min-height: 0px !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media only screen and (max-width: 1441px) {
}
/* Card Campaign */
/* Visit Thumbnail */


/* Collectibles Benefits */
.benefits-table {
  position: relative;
  width: 100%;
  height: auto;
  text-align: center;
  z-index: 2;
}
.benefits-table thead th {
  width: 16.66%;
  padding: 1.5rem 1rem .5rem;
  background-color: white;
  border-bottom: 1px solid var(--chakra-colors-gray-200);
  border-radius: 20px 20px 0 0;
}
.benefits-table thead th:first-child {
  width: 50%;
  background-color: transparent;
  border: none;
}
.benefits-table tbody tr td {
  padding: 1rem;
  background-color: white;
  border-bottom: 1px solid var(--chakra-colors-gray-200);
  border-right: 1px solid var(--chakra-colors-gray-200);
}
.benefits-table tbody tr td:last-child {
  border-right: none;
}
.benefits-table tbody tr:last-child td {
  border-bottom: none;
}
.tbl-th-br-right {
  position: relative;
}
.tbl-th-br-right::after {
  position: absolute;
  content: '';
  width: 1px;
  height: calc(100% - 18px);
  background-color: var(--chakra-colors-gray-200);
  bottom: 0px;
  right: -.5px;
  z-index: 9;
}
.tbl-br-top-left {
  border-radius: 20px 0 0 0;
}
.tbl-br-bottom-right {
  border-radius: 0 0 20px 0;
}
.tbl-br-bottom-left {
  border-radius: 0 0 0 20px;
}
/* Collectibles Benefits */

/* Event Done Separator */
.eventDone {
  position: relative;
  width: 100%;
}
.eventDone::before {
  position: absolute;
  content: '';
  width: 42%;
  height: .5px;
  background-color: var(--chakra-colors-gray-300);
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.eventDone::after {
  position: absolute;
  content: '';
  width: 42%;
  height: .5px;
  background-color: var(--chakra-colors-gray-300);
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
/* Event Done Separator */

.merchandise_img {
  width: 450px !important;
  height: auto !important;
}
@media only screen and (max-width: 991px) {
  .merchandise_img {
    width: 400px !important;
    height: auto !important;
  }
}
@media only screen and (max-width: 768px) {
  .merchandise_img {
    width: 300px !important;
    height: auto !important;
  }
}
